import React, { useState,useEffect} from "react"; 
import Logo from '../images/logo1.png'
import Placeholder from "../images/placeholder.jpg";
import styles from '../css/viewmembers.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from "react-router-dom";
import { faUserPlus,faUserGroup,faClipboardUser,faMoneyBill,faMoneyBill1,faReceipt,faUser,faHouse,faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";


function Allmembers(){
  const myComponentStyle = {
    paddingRight: '20px',
    color:'black',
  

  }
 
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch data from the server
    axios.get('https://appserver.cemkharistemple.com/allmembers')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter(user =>
    user.church_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const year=2020;

  return(
    
    <div className={styles.container}>
   
    <div className={styles.header}>
    <div className={styles.header_banner_right}>
            <img src={Logo} className={styles.header_logo}></img>
    
          </div>
          <header className={styles.headerbanner}>
      
          
           <div className={styles.header_banner}>
            Administrator <span className="material-icons-outlined" style={myComponentStyle} ><FontAwesomeIcon icon={faUser}/></span>
             <a href="/landing"> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faHouse}/> </span></a>
          <a href="/">  <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> </a> 
           </div>
        </header>
    
    </div>


    <div className={styles.sec_container}>
    <div class="header">

<h3>SEARCH MEMBER</h3>
<h5>(ID OR NAME SEARCH)</h5>
<img src="" alt=""/>
<div class="round_circle"></div>



</div>

<div>
      
      <input
        type="text"
        placeholder="Search by Name or Church Number"
        value={searchTerm}
        onChange={handleSearch}
        className={styles.searchbar}
      />
      <table className={styles.table}>
       
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.id} >
            <td className={styles.tablerow}><Link to ={`/updatemember/${user.church_id}`}>{user.church_id}</Link> </td>
              <td  className={styles.tablerow}>{user.first_name}</td>
              <td  className={styles.tablerow}>{user.last_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>


    


    </div>
    </div>
  )
}


export default  Allmembers;