import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Logo from '../images/logo1.png'
import Placeholder from "../images/placeholder.jpg";
import styles from '../css/members.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from "react-router-dom";

import { faUserPlus,faUserGroup,faClipboardUser,faMoneyBill,faMoneyBill1,faReceipt,faUser,faHouse,faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons'


function AddMemberKharis(){
  const [latestId, setLatestId] = useState('');


  useEffect(() => {
    axios.get('https://appserver.cemkharistemple.com/getLatestId')
      .then(response => {
        setLatestId(response.data.latestId);
      })
      .catch(error => {
        console.error('Error fetching latest ID:', error);
      });
  }, []);

  const church_id=latestId;

  const[newMemberData,setInputData]=useState({
    church_id:church_id,
    first_name:'' ,
    last_name:'' ,
    other_name: '',
    mobile_num:'',
    area:'' ,
    housenum:'' ,
    landmark: '',
    digital_add:'',
    hometown:'' ,
    region:'' ,
    pbox: '',
    email:'',
    dob:'',
    occupation:'' ,
    employer:'' ,
    work_address: '',
    work_contact_num:'',
    office_location:'' ,
    marital_status:'' ,
    marriage_type: '',
    spouse_name:'',
    children:'',
    department:'' ,
    service_type:'' ,
    date_joined: ''
  
  })

  console.log(newMemberData)


  const handleChange=(event)=>{
    const church_id_num=latestId;
    const name = event.target.name;
    const value=event.target.value;
    setInputData(values=>({...values, [name]:value,church_id:church_id_num}))

   
  }

  const navigate=useNavigate();
  const handleSave=async (event)=>{
    event.preventDefault();
  
    try {
      const response = await axios.post('https://appserver.cemkharistemple.com/savenewmember', {
        value: newMemberData,

      });
 
      console.log('Server response:', response.data);

const serverResponse=response.data.message
      if(serverResponse==="Success"){
        alert("Detils Submitted Successfully")
        navigate('/landing')
      }else{
        alert("details not saved")
      }
    } catch (error) {
      console.error('Error submitting to the server:', error);
    }
  };







  const myComponentStyle = {
    paddingRight: '10px',
    color:'black'
  }

  const headerStyle={
    display:'none'
  }
 
  const textBox1={
    width:'390px'
  }

  const textBox2={
    width:'770px'
  }
  const textBox3={
    width:'230px'
  }
  const textBox4={
    width:'600px'
  }
  const textBox5={
    width:'300px'
  }
  const textBox6={
    width:'390px'
  }
  const textBox7={
    width:'450px'
  }
  const textBox8={
    width:'700px'
  }
  const textBox9={
    width:'570px'
  }
  const textBox12={
    width:'530px'
  }
  const textBox13={
    width:'100%'
  }
  const textBox14={
    width:'500px'
  }
  const textBox15={
    width:'650px'
  }
  const textBox16={
    width:'390px'
  }



  return(
    <div className={styles.container}>
   
<div className={styles.header}>
<div className={styles.header_banner_right}>
        <img src={Logo} className={styles.header_logo}></img>

      </div>
      <header className={styles.headerbanner}>
   
      
       <div className={styles.header_banner}>
        <span className="material-icons-outlined" style={myComponentStyle} ><FontAwesomeIcon icon={faUser}/></span>
         <a href="/landing"> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faHouse}/> </span></a>
      <a href="/">  <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> </a> 
       </div>
    </header>

</div>




<div className={styles.sec_container}>

    <form onSubmit={handleSave}>
        <div className={styles.top_layer}>
        <div>
        <label>Church ID</label>
            <input type="text" name="church_id"  value={latestId} readOnly/>
        </div>
      
            <div className={styles.profile}>
            <input type="hidden" name="size" value="1000000"/>
                <img src={Placeholder} id="profileDisplay" onclick="triggerClick()"/>
                <input type="file" name="image" style={headerStyle} id="profileImage"
                    onchange="displayImage(this)"/>
            </div>

        </div>


        <div className={styles.personal_container}>

            <div className={styles.personal_info}>
                <h2>PERSONAL INFORMATION</h2>

                <h3>Full Name</h3>
                <div className={styles.text_pers}>
                    <input type="text" name="first_name" placeholder="First Name"  onChange={handleChange}  />
                    <input type="text" name="last_name" placeholder="Last Name" onChange={handleChange}/>
                    <input type="text" name="other_name" placeholder="other Name" onChange={handleChange}/>

                </div>

                <h3 className={styles.addi}>Address</h3>
                <div className={styles.field_form}>
                    <input type="text" name="mobile_num" placeholder="Mobile Number" style={textBox1} onChange={handleChange} />
                    <input type="text" name="area" placeholder="Area Of Residence"  style={textBox2}  onChange={handleChange}/>

                </div>
                <div className={styles.field_form}>
                    <input type="text" name="housenum" placeholder="House Number"  style={textBox3}  onChange={handleChange}/>
                    <input type="text" name="landmark" placeholder="Nearest Landmark" style={textBox4}  onChange={handleChange} />
                    <input type="text" name="digital_add" placeholder="Digital Address" style={textBox5}  onChange={handleChange} />
                </div>
                <div className={styles.field_form}>
                    <input type="text" name= "hometown" placeholder="Hometown"  style={textBox6} onChange={handleChange} />
                    <input type="text" name="region" placeholder="Region"  style={textBox7} onChange={handleChange} />
                </div>
                <div className={styles.field_form}>
                    <input type="text" name="pbox" placeholder="Mailing Address"  style={textBox9} onChange={handleChange}  />
                    <input type="text" name="email" placeholder="Email Address"  style={textBox9} onChange={handleChange} />
                </div>

                <div className={styles.field_form}>
                    <input type="text" name="dob" placeholder="Date of Birth" style={textBox12} onChange={handleChange}  />

                </div>

            </div>


            <div className={styles.employment_info}>
                <h2>EMPLOYMENT INFORMATION</h2>

                <h3>Employment Status</h3>

                <div className={styles.field_form}>
                    <input type="text" name="occupation" placeholder="Occupation" style={textBox9}  onChange={handleChange}/>
                    <input type="text" name="employer" placeholder="Employer" style={textBox9} onChange={handleChange}/>
                </div>
                <div className={styles.field_form}>
                    <input type="text" name="work_address" placeholder="Workplace Address" style={textBox13} onChange={handleChange}/>
                </div>
                <div className={styles.field_form}>
                    <input type="text" name="work_contact_num" placeholder="Work Contact Numeber"style={textBox14} onChange={handleChange} />
                    <input type="text" name="office_location" placeholder="Office Location"style={textBox15} onChange={handleChange} />
                </div>

            </div>

            <div className={styles.family_info}>
                <h2>FAMILY INFORMATION</h2>

                <h3>Marriage Status</h3>

                <div className={styles.field_form}>
                    <input type="text" name="marital_status" placeholder="Marital Status" style={textBox9} onChange={handleChange}/>
                    <input type="text"  name="marriage_type" placeholder="Type of Marriage"style={textBox9}  onChange={handleChange}/>
                </div>
                <div className={styles.field_form}>
                    <input type="text" name="spouse_name" placeholder="Name Of spouse" style={textBox13} onChange={handleChange} />
                </div>
                <div className={styles.field_form}>
                    <input type="text" name="children" placeholder="Children (Please give name & Birthday if any)"
                       style={textBox13} onChange={handleChange}/>
                </div>


            </div>

         <div className={styles.department_info}>
                <h2>DEPARTMENTAL INFORMATION & OTHERS</h2>



                <div className={styles.field_form}>
                    <input type="text" name="department" placeholder="Department"style={textBox9} onChange={handleChange} />
                    <input type="text" name="service_type" placeholder="preferred Service" style={textBox9} onChange={handleChange}/>
                </div>
                <div className={styles.field_form}>
                    <input type="text" name="date_joined" placeholder="Date Joined" style={textBox13}  onChange={handleChange}/>
                </div>

            </div>

            <div className={styles.form_buttons}>

                <button id="sub_button"    name="insert" type="submit">Submit form</button> &nbsp;&nbsp;
                <button id="cancel_button" type="button" onclick="window.location.href='kharis_temple.php';">Cancel</button>

            </div>
        </div>



    </form>

</div>



  



   </div>
  );
}


export default AddMemberKharis;
