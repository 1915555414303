import React, { useState } from "react"; 
import Logo from '../images/logo1.png'
import styles from '../css/index.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useNavigate } from "react-router-dom";




function LoginPage(){
  const [username,setUsername]=useState('')
  const [password,setPassword]=useState('')


  const navigate=useNavigate();
  const handleSubmit=async (event)=>{

    event.preventDefault();

    try{
     const response=await axios.post('https://appserver.cemkharistemple.com/login', {username,password});
     console.log(response.data)
     const errorMessage=response.data.message
     if(errorMessage=== "Success"){
       navigate('/landing')
     }else{
       alert("Wrong username or password")
     }

    }catch (error) {
      console.error('Login failed:', error);
      alert("Wrong username or password")
    }

  }

  return(
    <div className={styles.container}>
      <div className={styles.main_container}>
        <div className={styles.left_side}> 
        <div className={styles.left_items}>
        <img src={Logo} alt="logoImage"/>
        <h2>KHARIS TEMPLE ADMIN</h2>
        </div>

        </div>

        <div className={styles.right_side}>
       <div className={styles.right_items}> 
       <form onSubmit={handleSubmit}> 
         <div className={styles.login_items}>
           <h5>Hello</h5>
           <p className="ptag">Login To Get Started</p>
            <div className={styles.login_inputs}>
            
             <input type="text" name="" id="" placeholder="Username" onChange={e=>setUsername(e.target.value)} />
             <input type="password" name="" id="" placeholder="Password" onChange={e=>setPassword(e.target.value)}/>
             <button className={styles.login_button}>Login</button>
            </div>
         </div>
         </form>
       </div>
    
     </div>

      </div>
      
    </div>
  );
}


export default LoginPage;
