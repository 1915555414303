import React, { useState, useEffect } from "react"; 
import Logo from '../../images/logo1.png';
import styles from '../../css/landing.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import $ from 'jquery';
import 'datatables.net';
import { faUserPlus, faUserGroup, faClipboardUser, faMoneyBill, faMoneyBill1, faReceipt, faUser, faHouse, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';


function Attendancefaith() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10); 

  useEffect(() => {
    fetchDataFromAPI();
  }, []);

  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get('https://appserver.cemkharistemple.com/faithattendance');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); 
  
  };

  const pageCount = Math.ceil(data.length / perPage);
  const offset = currentPage * perPage;
  const currentPageData = data
    .filter((row) => {
      return (
        row.service_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.service_date.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.male.toString().includes(searchTerm.toLowerCase()) ||
        row.female.toString().includes(searchTerm.toLowerCase()) ||
        row.first_timers.toString().includes(searchTerm.toLowerCase()) ||
        row.total_count.toString().includes(searchTerm.toLowerCase())
      );
    })
    .slice(offset, offset + perPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const myComponentStyle = {
   paddingRight: '10px',
 }

 const myComponentImage = {
  width:'80px'
}

 const navigate=useNavigate();
 function addMemberFaith(){
  navigate("/addmemberfaith")
}


function payTitheFaith(){
 navigate("/paytithefaith")
}
function allMembersfaith(){
 navigate("/allmembersfaith")
}

function attendanceFaith(){
 navigate("/attendancefaith")
}

function payfruit(){
 navigate("/payfirstfruit")
}

  return(
   <div className={styles.container}>
    <header className={styles.header}>
     <div className={styles.menu_icons} onClick="openSide()" id="">
       <span className="material-icons-outlined">menu</span>
     </div>
       <div className={styles.header_content}>
         
       </div>
       <div className={styles.header_left}>
         <p style={myComponentStyle}> Administrator</p>   <span className="material-icons-outlined" style={myComponentStyle} ><FontAwesomeIcon icon={faUser}/></span>
         <a href="/landing"> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faHouse}/> </span></a>
      <a href="/">  <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> </a> 
       </div>
    </header>
 
 
 
    <aside className={styles.sidebar}  id="sidebar">
       <div className={styles.side_top} >
         <div className={styles.logo_img} > 
           <img src={Logo} alt="" style={myComponentImage} />
         </div> 
         <div className={styles.logo_name}>
           <p>Kharis <span className={styles.other_name}> Admin </span>  </p>
         </div>
       </div>
 
       <ul className={styles.side_list}>
       <li className={styles.side_list_item} onClick={addMemberFaith}>  <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faUserPlus}/> </span> Add Member</li>
         <li className={styles.side_list_item} onClick={allMembersfaith}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faUserGroup}/> </span> View Members</li>
         <li className={styles.side_list_item}  onClick={attendanceFaith}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faClipboardUser}/></span>Attendance Records</li>
         <li className={styles.side_list_item} onClick={payTitheFaith}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faMoneyBill}/> </span> Tithe</li>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faMoneyBill1}/></span> First Fruit</li>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faReceipt}/></span> Finance</li>
       </ul>
     
     
     </aside>
     <main className={styles.main_content}>
   
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10 bg-light rounded my-2 py-2">
                <h4 className="text-center text-dark pt-2">CEM Kharis Temple Attendance</h4>
                <div className="input-group mb-3 mt-3">
                  <input type="text" className="form-control" placeholder="Search" value={searchTerm} onChange={handleSearch} />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button">Search</button>
                  </div>
                </div>
                <hr />
                <table id="attendanceTable" className="table table-bordered table-striped table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>ID</th>
                      <th>Service Type</th>
                      <th>Service Date</th>
                      <th>Male</th>
                      <th>Female</th>
                      <th>First Timers</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData.map((row, index) => (
                      <tr key={index}>
                        <td>{offset + index + 1}</td>
                        <td>{row.service_type}</td>
                        <td>{row.service_date}</td>
                        <td>{row.male}</td>
                        <td>{row.female}</td>
                        <td>{row.first_timers}</td>
                        <td>{row.total_count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
     


    </main>
   </div>
  );
}


export default Attendancefaith;
