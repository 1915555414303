import React, { useState,useEffect } from 'react';
import axios from 'axios';

const SmsSender = () => {
  const [to, setTo] = useState('');
  const [message, setMessage] = useState('');

  const from='CEM KHARIS';
  const app_id='e14763bb-acc1-43d6-852c-d419d5f8620c';
  const app_secret='XtLilZVgjUCZcsovbwpnffY4SOKMkVWz112DU1tAYxc';
  const type='1';

  const handleSendSMS = async () => {
    try {
      const response = await fetch('https://api.wittyflow.com/v1/messages/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to, message,from,app_id,app_secret,type }),
      });

      if (response.ok) {
        console.log('SMS sent successfully!');
      } else {
        console.error('Failed to send SMS:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending SMS:', error.message);
    }
  };

  return (
    <div>
      <label>
        To:
        <input type="text" value={to} onChange={(e) => setTo(e.target.value)} />
      </label>
      <br />
      <label>
        Message:
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
      </label>
      <br />
      <button onClick={handleSendSMS}>Send SMS</button>
    </div>
  );
};


export default SmsSender;
