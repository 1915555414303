import React, { useState } from "react"; 
import Logo from '../images/logo1.png'
import styles from '../css/landing.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate,useHistory } from "react-router-dom";
import { faPeopleGroup,faBorderAll,faChurch,faListUl,faCoins,faClipboardUser,faListCheck,faUser,faHouse,faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons'










function Landing(){
  const myComponentStyle = {
   paddingRight: '10px',
 }

 const myComponentImage = {
  width:'80px'
}

 const navigate=useNavigate();
 function kharispage(){
   navigate("/kharispage")
 }

 function faithpage(){
  navigate("/faithpage")
}

 function landingpage(){
  navigate("/landing")
}

 const history = useNavigate();

 const handleLogout = () => {
  localStorage.removeItem('accessToken');
  window.location.href='/'
 };

  return(
    <div className={styles.container}>
    <header className={styles.header}>
     <div className={styles.menu_icons} onClick="openSide()" id="">
       <span className="material-icons-outlined">menu</span>
     </div>
       <div className={styles.header_content}>
         
       </div>
       <div className={styles.header_left}>
         <span className="material-icons-outlined" style={myComponentStyle} ><FontAwesomeIcon icon={faUser}/></span>
         <a onClick={landingpage}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faHouse}/> </span></a>
      <a  onClick={handleLogout}>  <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> </a> 
       </div>
    </header>
 
 
 
     <aside className={styles.sidebar}  id="sidebar">
       <div className={styles.side_top} >
         <div className={styles.logo_img} > 
           <img src={Logo} alt="" style={myComponentImage}/>
         </div> 
         <div className={styles.logo_name}>
           <p>Kharis <span className={styles.other_name}> Admin </span>  </p>
         </div>
       </div>
 
       <ul className={styles.side_list}>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faBorderAll}/> </span> DashBoard</li>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faChurch}/> </span> Branch Report</li>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faListUl}/></span>Departmental Report</li>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faCoins}/> </span> Finance</li>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faClipboardUser}/></span> Attendance</li>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faListCheck}/></span> Equipment Check</li>
       </ul>
     
     
     </aside>
    <main className={styles.main_content}>
     <div className={styles.main_title}>
       <h2>Dashboard</h2>
     </div>
     <div className={styles.main_cards}>
       <div className={styles.card} onClick={(e)=>kharispage()}>
         <div className={styles.card_inner} >
          <h3>Kharis Temple</h3>
           <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faPeopleGroup}/></span>
         </div>
    
     
 
        
       </div>
 
       <div className={styles.card}>
         <div className={styles.card_inner} onClick={(e)=>faithpage()} >
           <h3>Faith Temple</h3>
         <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faPeopleGroup}/> </span>
         </div>
       
       </div>
 
       <div className={styles.card}>
         <div className={styles.card_inner} >
           <h3>Goaso Temple</h3>
           <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faPeopleGroup}/> </span>
         </div>
         
       </div>

       
 
     
     </div>
    </main>
   </div>
  );
}


export default Landing;
