import React, { useState,useEffect } from "react"; 
import Logo from '../images/logo1.png'
import styles from '../css/landing.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from "react-router-dom";
import AddMemberKharis from "./addMember";
import axios from "axios";
import CanvasJSReact from '@canvasjs/react-charts';
import {faPeopleGroup,faUserPlus,faUserGroup,faClipboardUser,faMoneyBill,faMoneyBill1,faReceipt,faUser,faHouse,faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons'


const CanvasJSChart = CanvasJSReact.CanvasJSChart;
function Faithhome(){
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get('https://appserver.cemkharistemple.com/faithallmembers')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  console.log(users.length)

const totalMembers=users.length;





const [data, setData] = useState([]);

useEffect(() => {
  fetchDataFromDatabase();
}, []);

const fetchDataFromDatabase = async () => {
  try {
    const response = await fetch('https://appserver.cemkharistemple.com/faithattendance');
    const result = await response.json();
    const dataArray = processData(result); 
     console.log(dataArray)
  
    setData(dataArray);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const processData = (apiData) => {
  return apiData.map(item => ({ label: item.service_date, y: parseFloat(item.total_count) }));
};

const options = {
  title: {
    text: 'Attendance Chart',
  },
  axisY: {
    title: "Total Count",
  },
  axisX: {
    title: "Service Date",
    labelAngle: -60
  },
  data: [
    {
      type: 'line',
      dataPoints: data,
    },
  ],
};


  const myComponentStyle = {
   paddingRight: '10px',
 }

 const myComponentImage = {
  width:'80px'
}

 const navigate=useNavigate();
 function addMemberFaith(){
   navigate("/addmemberfaith")
 }


 function payTitheFaith(){
  navigate("/paytithefaith")
}
function allMembersfaith(){
  navigate("/allmembersfaith")
}

function attendanceFaith(){
  navigate("/attendancefaith")
}

function payfruit(){
  navigate("/payfirstfruitfaith")
}

  return(
   <div className={styles.container}>
    <header className={styles.header}>
     <div className={styles.menu_icons} onClick="openSide()" id="">
       <span className="material-icons-outlined">menu</span>
     </div>
       <div className={styles.header_content}>
         
       </div>
       <div className={styles.header_left}>
         <p style={myComponentStyle}> Administrator</p>   <span className="material-icons-outlined" style={myComponentStyle} ><FontAwesomeIcon icon={faUser}/></span>
         <a href="/landing"> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faHouse}/> </span></a>
      <a href="/">  <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> </a> 
       </div>
    </header>
 
 
 
    <aside className={styles.sidebar}  id="sidebar">
       <div className={styles.side_top} >
         <div className={styles.logo_img} > 
           <img src={Logo} alt="" style={myComponentImage} />
         </div> 
         <div className={styles.logo_name}>
           <p>Faith <span className={styles.other_name}> Admin </span>  </p>
         </div>
       </div>
 
       <ul className={styles.side_list}>
       <li className={styles.side_list_item} onClick={addMemberFaith}>  <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faUserPlus}/> </span> Add Member</li>
         <li className={styles.side_list_item} onClick={allMembersfaith}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faUserGroup}/> </span> View Members</li>
         <li className={styles.side_list_item} onClick={attendanceFaith} > <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faClipboardUser}/></span>Attendance Records</li>
         <li className={styles.side_list_item} onClick={payTitheFaith}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faMoneyBill}/> </span> Tithe</li>
         <li className={styles.side_list_item} onClick={payfruit}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faMoneyBill1}/></span> First Fruit</li>
         <li className={styles.side_list_item}> <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faReceipt}/></span> Finance</li>
       </ul>
     
     
     </aside>
     <main className={styles.main_content}>
     <div className={styles.main_title}>
       <h2>Dashboard</h2>
     </div>
     <div className={styles.main_cards}>
       <div className={styles.card} >
         <div className={styles.card_inner} >
          <h3>Faith Temple</h3>
           <span className="material-icons-outlined" style={myComponentStyle}><FontAwesomeIcon icon={faPeopleGroup}/></span>
           <p className={styles.number_text}>{totalMembers} </p>
         </div>
        
       </div>
       
     </div>
     <div className={styles.card} >
        <div>
        <CanvasJSChart options={options} />
        </div>

      </div>


    </main>
   </div>
  );
}


export default Faithhome;
