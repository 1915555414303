import React, { useState, useEffect } from 'react';
import Logo from '../images/logo1.png'
import { useParams } from "react-router-dom";
import styles from '../css/tithe.module.css';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

function Paymoney() {
  const { church_id } = useParams();
  const [user, setUser] = useState(null);
  const [titheData, setTitheData] = useState([]);
  const navigate = useNavigate();

  var today_date = new Date();
  var today_month = today_date.getMonth() + 1;
  var today_day = today_date.getDate();
  var today_year = today_date.getFullYear();

  if (today_month < 10) {
    today_month = "0" + today_month;
  }
  if (today_day < 10) {
    today_day = "0" + today_day;
  }

  const itemdate = today_day + "-" + today_month + "-" + today_year;

  useEffect(() => {
    fetch(`https://appserver.cemkharistemple.com/paytithe/${church_id}`)
      .then(response => response.json())
      .then(data => setUser(data))
      .catch(error => console.error('Error fetching user details:', error));
  }, [church_id]);

  useEffect(() => {
    const fetchTitheData = async () => {
      try {
        const response = await fetch(`https://appserver.cemkharistemple.com/testpage/${church_id}`);
        const data = await response.json();
        setTitheData(data);
      } catch (error) {
        console.error('Error fetching tithe data:', error);
      }
    };

    fetchTitheData();
  }, [church_id]);

  const groupedData = titheData.reduce((acc, row) => {
    const year = new Date(row.date).getFullYear();
    if (!acc[year]) {
      acc[year] = { months: {}, amount: 0 };
    }
    const month = new Date(row.date).getMonth() + 1;
    if (!acc[year].months[month]) {
      acc[year].months[month] = 0;
    }
    acc[year].months[month] += parseInt(row.amount); // Parse to integer and accumulate the amount for the month
    acc[year].amount += parseInt(row.amount); // Parse to integer and accumulate the total amount for the year
    return acc;
  }, {});

  const uniqueMonths = Array.from(new Set(titheData.map(row => new Date(row.date).getMonth() + 1)))
    .sort((a, b) => a - b);

  const [inputs, setInputData] = useState({
    church_id: church_id,
    date_entry: itemdate,
    amount: '',
    date: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://appserver.cemkharistemple.com/savetithe', { value: inputs });
      console.log('Server response:', response.data);
    } catch (error) {
      console.error('Error submitting to the server:', error);
    }
  };

  const [conf, setConfirm] = useState({
    mobile_num: '',
    month: '',
    amount: '',
  });

  const handleConfirm = (event) => {
    event.preventDefault();
    const mobileNum = user.mobile_num;
    const selectedMonth = event.target.value;
    const enteredAmount = inputs.amount;
    setConfirm(prevState => ({
      ...prevState,
      mobile_num: mobileNum,
      month: selectedMonth,
      amount: enteredAmount,
    }));
  };

  const finalMobile = conf.mobile_num;
  const finalAmount = inputs.amount;
  const finalMonth = conf.month;

  const from = 'CEM KHARIS';
  const app_id = 'e14763bb-acc1-43d6-852c-d419d5f8620c';
  const app_secret = 'XtLilZVgjUCZcsovbwpnffY4SOKMkVWz112DU1tAYxc';
  const type = '1';
  const to = finalMobile;
  console.log("cccc"+ to)
  const message = `Your Tithe of GHS ${finalAmount} has been received for the month of ${finalMonth}. As you Honour the Lord with thy substance, So shall you be blessed.Shalom`;

  const handleSendSMS = async () => {
    try {
      const response = await fetch('https://api.wittyflow.com/v1/messages/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to, message, from, app_id, app_secret, type }),
      });
      if (response.ok) {
        console.log('SMS sent successfully!');
        setTimeout(() => {
          window.location.reload();
        }, 200);
      } else {
        console.error('Failed to send SMS:', response.statusText);
        
      }
    } catch (error) {
      console.error('Error sending SMS:', error.message);
    }
  };

  function tithePage() {
    navigate("/paytithe");
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  const headerStyle = { display: 'none' };
  const textBox1 = { width: 'margin-right: 0px 10px' };
  const textBox2 = { width: '300px' };
  const textBox3 = { width: '230px' };
  const fullName = `${user.first_name} ${user.last_name}`;

  return (
    <div>
      {user ? (
        <div>
          <div className={styles.header}>
            <div className={styles.title}>
              <h3>Financial Records</h3>
              <p>(Tithe Records)</p>
            </div>
            <div className={styles.logo}>
              <img src={Logo} alt=""/>
            </div>
          </div>
          <div className={styles.formgroup}>
            <div className={styles.photo}>
              <div className={styles.idmem}>
                <input type="text" placeholder=" " style={textBox1} value={user.church_id}/>
                <input type="text" placeholder=" " style={textBox2} value={fullName} readOnly/>
              </div>
            </div>
          </div>
          <div className={styles.personinfo}>
            <h3 className={styles.headinginfo}>
              TITHE RECORDS
            </h3>
            <div className={styles.outerwrapper}>
              <div className={styles.tablewrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>Year</th>
                      {uniqueMonths.map(month => (
                        <th key={month}>{new Date(2000, month - 1, 1).toLocaleString('default', { month: 'short' })}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(groupedData).map(([year, { months, amount }]) => (
                      <tr key={year}>
                        <td>{year}</td>
                        {uniqueMonths.map(month => (
                          <td key={month}>{months[month] || 0}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.allitems}>
              <form onSubmit={handleSave}>
             
                <h2>Pay Tithe</h2>
                <div className={styles.groupinfo}>
                  <div className={styles.textpers}>
                    <label>Enter Amount</label>
                    <input type="text" name="amount" placeholder=" " onChange={handleChange}/>
                  </div>
                  <div className={styles.textpers} >
                    <label>Current Date</label>
                    <input type="text" name="date_entry" placeholder="" value={itemdate} disabled/>
                  </div>
                </div>
                <div className={styles.groupinfo}>
                  <div className={styles.textpers}>
                    <label>Month Of Tithe</label>
                    <select name="month" className={styles.dropdown} onChange={handleConfirm}>
                      <option selected disabled value="">Choose...</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select>
                  </div>
                  <div className={styles.textpers}>
                    <label>Date of Entry</label>
                    <input type="date" name="date" placeholder="" onChange={handleChange}/>
                    <input type="text" name="mobile_num" value={user.mobile_num} hidden/>
                  </div>
                </div>
                <div className={styles.formbuttons}>
                  <button onClick={handleSendSMS} className={styles.submit_button}>Submit Input</button>
                  <button onClick={tithePage} className={styles.confirm_button}>Confirm</button>
                  <button className={styles.cancel_button} onClick={tithePage}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Paymoney;
