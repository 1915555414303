import LoginPage from './pages/loginPage';
import Landing from './pages/landing';
import Kharishome from './pages/kharisLanding';
import AddMemberKharis from './pages/addMember';
import Paytithe from './pages/membersTithe';
import Paymoney from './pages/paytithe';
import Allmembers from './pages/allMembers';
import Attendance from './pages/attendance';
import Testingpage from './pages/testPage';
import Updatemember from './pages/updateMember';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SmsSender from './pages/testPageAll';
import PayFirstFruit from './pages/membersfirstfruit';
import Payfruit from './pages/payfirstfruit';
import Faithhome from './pages/faithLanding';
import AddMemberFaith from './pages/faithtemple/addMember';
import Allmembersfaith from './pages/faithtemple/allMembers';
import Paymoneyfaith from './pages/faithtemple/paytithe';
import Paytithefaith from './pages/faithtemple/membersTithe';
import Attendancefaith from './pages/faithtemple/attendance';
import Updatememberfaith from './pages/faithtemple/updateMember';
import Payfruitfaith from './pages/faithtemple/payfirstfruit';


function App() {
  return (
    <div className="App">
         <Routes>

      <Route path="/" element={<LoginPage />} />
      <Route path="/landing" element={<Landing />} />
      <Route path="/kharispage" element={<Kharishome />} />
      <Route path="/faithpage" element={<Faithhome />} />
      <Route path="/addmember" element={<AddMemberKharis />} />
      <Route path="/addmemberfaith" element={<AddMemberFaith />} />
      <Route path="/allmembers" element={<Allmembers />} />
      <Route path="/allmembersfaith" element={<Allmembersfaith />} />
      <Route path="/paytithe" element={<Paytithe />} />
      <Route path="/paytithefaith" element={<Paytithefaith />} />
      <Route path="/payfirstfruit" element={<PayFirstFruit />} />
      <Route path="/smssend" element={<SmsSender />} />
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/attendancefaith" element={<Attendancefaith />} />
      <Route path="/paytithe/:church_id" element={<Paymoney />} />
      <Route path="/paytithefaith/:church_id" element={<Paymoneyfaith />} />
      <Route path="/payfirstfruit/:church_id" element={<Payfruit />} />
      <Route path="/payfirstfruitfaith/:church_id" element={<Payfruitfaith />} />
      <Route path="/updatemember/:church_id" element={<Updatemember />} />
      <Route path="/updatememberfaith/:church_id" element={<Updatememberfaith />} />

      </Routes>
    
    </div>
  );
}

export default App;
